import { useAuth } from "@wojo/auth";
import LogRocket from "logrocket";
import { useEffect } from "react";

/**
 * @summary Initializes LogRocket. If the user is logged in, it will identify the user.
 * @note This hook must be within the scope of the AuthContextProvider.
 */
export const useLogRocketIdentify = () => {
    const { customer, isLoggedIn } = useAuth();
    useEffect(() => {
        const logRocketAppId = process.env.NEXT_PUBLIC_LOG_ROCKET_APP_ID;
        const isEnabled =
            process.env.NEXT_PUBLIC_IS_LOG_ROCKET_ENABLED === "true";
        if (logRocketAppId && isEnabled) {
            if (isLoggedIn && customer?.customerId) {
                LogRocket.identify(customer.customerId, {
                    name: `${customer.firstName} ${customer.lastName}`,
                    email: customer.email ?? "",
                });
            }
        }
    }, [customer, isLoggedIn]);
};
