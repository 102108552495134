import LogRocket from "logrocket";
import LogrocketFuzzySanitizer from "logrocket-fuzzy-search-sanitizer";

/**
 * @description initialize LogRocket before Apollo to attach LogRocket to `fetch` and `xhr` requests
 * https://docs.logrocket.com/docs/troubleshooting-sessions#other-network-libraries.
 *
 * Conditional recording (including site paths allowlisted for recording) can be
 * configured under `settings/conditional-recording` for a given LogRocket project.
 */
export const initLogRocket = () => {
    if (typeof window !== "undefined") {
        const logRocketAppId = process.env.NEXT_PUBLIC_LOG_ROCKET_APP_ID;
        const isEnabled =
            process.env.NEXT_PUBLIC_IS_LOG_ROCKET_ENABLED === "true";
        if (logRocketAppId && isEnabled) {
            const { requestSanitizer, responseSanitizer } =
                LogrocketFuzzySanitizer.setup([
                    "password",
                    "email",
                    "identityDocumentNumber",
                    "issueDate",
                    "expirationDate",
                    "accountNumber",
                ]);
            LogRocket.init(logRocketAppId, {
                release: process.env.VERCEL_GIT_COMMIT_SHA,
                network: {
                    // @ts-ignore bad vendor types
                    requestSanitizer,
                    // @ts-ignore bad vendor types
                    responseSanitizer,
                },
            });
        }
    }
};
